export const INSTRUMENTATION_TYPES = {
  LOGIN: "LOGIN",
  DEMO_LOGIN: "DEMO_LOGIN",
  LOBBY_GET_CHILD_DETAILS: "LOBBY_GET_CHILD_DETAILS",
  LOBBY_GET_CHILD_TOURNAMENTS: "LOBBY_GET_CHILD_TOURNAMENTS",
  USER_STATS_VIEWED: "USER_STATS_VIEWED",
  ENTER_LEADERBOARD: "ENTER_LEADERBOARD",
  POST_GAME_LEADERBOARD_BUTTON_CLICKED: "POST_GAME_LEADERBOARD_BUTTON_CLICKED",
  POST_DEMO_GAME_LEADERBOARD_BUTTON_CLICKED:
    "POST_DEMO_GAME_LEADERBOARD_BUTTON_CLICKED",
  TRIALGAME_POPUP_START_BUTTON_CLICKED: "TRIALGAME_POPUP_START_BUTTON_CLICKED",
  POST_TRIAL_ISSUE_BUTTON_CLICKED: "POST_TRIAL_ISSUE_BUTTON_CLICKED",
  POST_TRIAL_READY_BUTTON_CLICKED: "POST_TRIAL_READY_BUTTON_CLICKED",
  SUBMIT_TIME_LOG: "SUBMIT_TIME_LOG",
  TRANSFER_TO_VAULT: "TRANSFER_TO_VAULT",
  VIEW_REWARDS: "VIEW_REWARDS",
  REDEEM_REWARDS: "REDEEM_REWARDS",
  REGISTER_TOURNAMENT: "REGISTER_TOURNAMENT",
  SUBSCRIBE_NOW: "SUBSCRIBE_NOW",
  REWARD_PROCEED: "REWARD_PROCEED",
  SUBSCRIPTION_PAY: "SUBSCRIPTION_PAY",
  INVITE_FRIENDS: "INVITE_FRIENDS",
  PLACE_ORDER: "PLACE_ORDER",
  ENTER_TOURNAMENT: "ENTER_TOURNAMENT",
  LEADERBOARD_BACK: "LEADERBOARD_BACK",
  COMMON_ROOM_BACK: "COMMON_ROOM_BACK",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  ARENA_PLAY: "ARENA_PLAY",
  ARENA_LEADERBOARD: "ARENA_LEADERBOARD",
  ARENA_HELP: "ARENA_HELP",
  GAME_TILE_CLICK: "GAME_TILE_CLICK",
  GAME_TILE_CLICK_ALLGAMES: "GAME_TILE_CLICK_ALLGAMES",
  FIND_PLAYER: "FIND_PLAYER",
  CHALLENGE_FRIEND: "CHALLENGE_FRIEND",
  GAME_EXIT_YES: "GAME_EXIT_YES",
  GAME_EXIT_NO: "GAME_EXIT_NO",
  GO_BACK: "GO_BACK",
  REMATCH_GAME_END: "REMATCH_GAME_END",
  PLAY_AGAIN_GAME_END: "PLAY_AGAIN_GAME_END",
  OTHER_GAMES_GAME_END: "OTHER_GAMES_GAME_END",
  LEADERBOARD_GAME_END: "LEADERBOARD_GAME_END",
  ARENA_LOBBY_GAME_END: "ARENA_LOBBY_GAME_END",
  REMATCH_ACCEPTED: "REMATCH_ACCEPTED",
  REMATCH_DECLINED: "REMATCH_DECLINED",
  OPENMATCH_ACCEPTED: "OPENMATCH_ACCEPTED",
  CHALLENGE_CLICK_ARENAHOME: "CHALLENGE_CLICK_ARENAHOME",
  PAYMENT_WITH_PHONENUMBER: "PAYMENT_WITH_PHONENUMBER",
};

