import React from "react";

const NonPodiumProfileIcon = () => {

    return (
        <svg width="40" height="55" viewBox="0 0 40 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_4085_2820" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="15" width="40" height="40">
<path d="M40 35C40 23.9543 31.0457 15 20 15C8.9543 15 0 23.9543 0 35C0 46.0457 8.9543 55 20 55C31.0457 55 40 46.0457 40 35Z" fill="white"/>
</mask>
<g mask="url(#mask0_4085_2820)">
<path d="M40 15H0V55H40V15Z" fill="#979797"/>
<path d="M-5.8571 41.5774C0.581554 51.8814 14.1542 55.0149 24.4582 48.5762C34.7622 42.1375 37.8957 28.5649 31.457 18.2609C25.0184 7.95691 11.4457 4.82342 1.14173 11.2621C-9.16228 17.7007 -12.2958 31.2734 -5.8571 41.5774Z" fill="#CCF900"/>
<path d="M11.9844 33.4023C13.9651 34.5934 16.1527 34.9755 18.547 34.5487" stroke="black" strokeWidth="2.22222" strokeLinecap="round"/>
<path d="M9.85378 27.6278C10.0022 27.0831 9.8041 26.5385 9.41134 26.4116C9.01859 26.2846 8.57989 26.6232 8.43148 27.168C8.28308 27.7127 8.48117 28.2573 8.87392 28.3843C9.26668 28.5113 9.70538 28.1726 9.85378 27.6278Z" fill="black"/>
<path d="M23.0579 29.4833C23.2063 28.9386 23.0082 28.394 22.6154 28.267C22.2227 28.14 21.784 28.4787 21.6356 29.0235C21.4872 29.5682 21.6853 30.1128 22.078 30.2397C22.4708 30.3667 22.9095 30.0281 23.0579 29.4833Z" fill="black"/>
</g>
<path d="M39.902 34.9995C23.7305 34.188 7.39586 28.8665 1.5 26.4995C1.5 25.4851 8.56968 12.1754 22.7198 14.2042C38.0827 17.0445 40.5758 29.2512 39.902 34.9995Z" fill="black"/>
<path d="M35 9L31 17.5C33.8 18.7 36.8333 22.6667 37.5 24.5L35 9Z" fill="black"/>
<path d="M15.5 8L9 18.5C10.2 17.3 14.5 16 15.5 16V8Z" fill="black"/>
<path d="M10.2465 24.3486L14.1005 26.1915L9.98503 27.3372L6.13099 25.4943L10.2465 24.3486Z" fill="#CCF900"/>
<path d="M22.3979 26.8642L26.2519 28.7072L22.1364 29.8528L18.2824 28.0099L22.3979 26.8642Z" fill="#CCF900"/>
</svg>


    );

}

export default NonPodiumProfileIcon;