const ThunderIcon = ({ className }) => {
    return (
      <svg
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M2.80429 12.4657C2.71203 12.4287 2.63537 12.3635 2.58639 12.2805C2.53741 12.1975 2.5189 12.1013 2.5338 12.0071L3.3543 6.92864H1.17682C1.10778 6.93041 1.03925 6.91707 0.976495 6.88967C0.913742 6.86227 0.858451 6.82152 0.814887 6.77058C0.771324 6.71965 0.740652 6.65988 0.725239 6.59588C0.709826 6.53189 0.710084 6.46539 0.725992 6.40151L2.07846 0.830149C2.10226 0.734266 2.16008 0.649105 2.2422 0.589001C2.32431 0.528897 2.42569 0.49752 2.52929 0.500153H7.03753C7.10488 0.499935 7.17143 0.514066 7.23228 0.541506C7.29313 0.568947 7.34674 0.609 7.38917 0.658723C7.4322 0.709007 7.46267 0.767915 7.47829 0.831013C7.49391 0.894111 7.49427 0.959755 7.47934 1.023L6.69941 4.35725H8.84083C8.92532 4.35709 9.00816 4.3795 9.0799 4.42194C9.15164 4.46437 9.20939 4.52512 9.24657 4.59724C9.27887 4.66647 9.2913 4.74261 9.28259 4.81789C9.27388 4.89317 9.24434 4.96491 9.19698 5.02581L3.33626 12.3114C3.29665 12.3672 3.24377 12.4134 3.18189 12.4462C3.12001 12.4789 3.05089 12.4974 2.98011 12.5C2.91979 12.4989 2.86019 12.4873 2.80429 12.4657ZM5.57235 5.21438L6.474 1.35729H2.88995L1.74485 6.07151H4.40922L3.69241 10.4772L7.93918 5.21438H5.57235Z"
          fill="currentColor"
        />
      </svg>
    );
};

export default ThunderIcon;